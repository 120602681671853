<template>
  <v-row no-gutters class="ma-0 pa-0">
    <v-col cols="12">
      <v-row no-gutters>
        <v-col cols="12" md="3">
          <v-radio-group label="Cash" v-model="moneyType" prepend-icon="money" row>
            <v-radio label="MMK" value="mmk"></v-radio>
            <v-radio label="USD" value="usd"></v-radio>
          </v-radio-group>
        </v-col>
        <v-col cols="12" md="4">
          <v-radio-group v-model="way.passType" row :disabled="!isUpdate">
            <v-radio value="ADULT" :label="`Adult`"></v-radio>
            <v-radio value="CHILD" :label="`Child`"></v-radio>
            <v-radio value="INFANT" :label="`Infant`">
              <!-- <v-label>
                    Infant
                    <v-chip color="primary" outlined @click="way.pass = infant">{{infant}}</v-chip>
              </v-label>-->
            </v-radio>
          </v-radio-group>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="12" md="1">
          <v-text-field
            label="Pax"
            v-model.number="way.pass"
            :readonly="!isUpdate"
            prepend-icon="group"
          />
        </v-col>
        <v-col cols="12" md="1" offset-md="1">
          <v-text-field
            label="Fare"
            type="number"
            v-model.number="way.fare"
            prepend-icon="local_offer"
          />
        </v-col>
        <v-col cols="12" md="8" offset-md="1">
          <v-row no-gutters>
            <v-col cols="5" md="1">
              <v-text-field label="BaseFare" type="number" v-model.number="way.baseFare" />
            </v-col>
            <v-col cols="1" class="pt-4 text-center">X</v-col>
            <v-col cols="5" md="1">
              <v-text-field label="Com %" type="number" v-model.number="way.commissionPercent" />
            </v-col>
            <v-col>
              <v-btn class="mt-2 ml-3" rounded color="primary" @click="calculate">Calculate</v-btn>
            </v-col>
          </v-row>
        </v-col>
        <v-row no-gutters>
          <v-col cols="12" md="1">
            <v-text-field label="Commission" type="number" v-model.number="way.commission" />
          </v-col>
          <v-col cols="12" md="1" offset-md="1">
            <v-text-field
              v-if="moneyType == 'usd'"
              readonly
              label="Total Commission"
              v-model.number="way.usdTotalCommission"
            />
            <v-text-field
              v-else
              readonly
              label="Total Commission"
              v-model.number="way.mmkTotalCommission"
            />
          </v-col>
          <v-col cols="12" md="1" offset-md="1">
            <v-text-field
              v-if="moneyType == 'usd'"
              label="Amount USD"
              type="number"
              v-model.number="way.usdAmount"
            />
            <v-text-field v-else label="Amount MMK" type="number" v-model.number="way.mmkAmount" />
          </v-col>
          <v-col cols="12" md="1" offset-md="1">
            <v-text-field label="Total" type="number" :value="total" readonly />
          </v-col>
        </v-row>
      </v-row>
    </v-col>
  </v-row>
</template>

<style scoped></style>

<script>
export default {
  data() {
    return {
      moneyType: "mmk"
    };
  },
  props: {
    way: {
      type: Object,
      default: () => {
        return {
          travelDate: "", //16 Dec
          flight: {
            flightId: 0,
            flightName: ""
          },
          className: "",
          dept: "", //11:10
          fromSector: {
            sectorId: 0,
            sectorName: "",
            shortName: ""
          },
          toSector: {
            sectorId: 0,
            sectorName: ""
          },
          pass: 0,
          passType: "ADULT", //CHILD, INFANT
          fare: 0,
          baseFare: 0,
          commissionPercent: 0,
          commission: 0,
          usdTotalCommission: 0,
          mmkTotalCommission: 0,
          usdAmount: 0,
          mmkAmount: 0
        };
      }
    },
    adult: {
      type: Number,
      default: 0
    },
    child: {
      type: Number,
      default: 0
    },
    infant: {
      type: Number,
      default: 0
    },
    isUpdate: Boolean,
    dialog: Boolean
  },
  methods: {
    calculate: function() {
      this.way.commission =
        this.way.commissionPercent * this.way.baseFare * 0.01;
    },
    calculateTotal: function() {
      let result = 0;

      result += this.way.fare * this.way.pass;
      if (this.moneyType == "mmk") {
        //result -= this.way.mmkTotalCommission;
        this.way.mmkAmount = result;
        this.way.usdAmount = 0;
      } else {
        //result -= this.way.usdTotalCommission;
        this.way.mmkAmount = 0;
        this.way.usdAmount = result;
      }
    },
    calculateTotalCommission: function() {
      let result = 0;
      result += this.way.commission * this.way.pass;
      if (this.moneyType == "mmk") {
        this.way.mmkTotalCommission = result;
        this.way.usdTotalCommission = 0;
      } else {
        this.way.mmkTotalCommission = 0;
        this.way.usdTotalCommission = result;
      }
    }
  },
  computed: {
    total: function() {
      let result = 0;
      if (this.moneyType == "mmk") {
        //result -= this.way.mmkTotalCommission;
        result = this.way.mmkAmount - this.way.mmkTotalCommission;
      } else {
        //result -= this.way.usdTotalCommission;
        result = this.way.usdAmount - this.way.usdTotalCommission;
      }
      return result;
    }
  },
  watch: {
    dialog: function() {
      if (this.way.wayId != 0) {
        if (this.way.mmkAmount > 0) {
          this.moneyType = "mmk";
        } else {
          this.moneyType = "usd";
        }
      }
    },
    "way.pass": function(value) {
      this.calculateTotal();
      this.calculateTotalCommission();
    },
    "way.fare": function(value) {
      this.calculateTotal();
    },
    "way.commission": function(value) {
      this.calculateTotalCommission();
    },
    "way.usdTotalCommission": function(value) {
      this.calculateTotal();
    },
    "way.mmkTotalCommission": function(value) {
      this.calculateTotal();
    },
    moneyType: function(value) {
      let result = 0;
      result += this.way.commission * this.way.pass;
      if (this.moneyType == "mmk") {
        this.way.mmkTotalCommission = result;
        this.way.usdTotalCommission = 0;
      } else {
        this.way.mmkTotalCommission = 0;
        this.way.usdTotalCommission = result;
      }
      this.calculateTotal();
    }
  }
};
</script>
