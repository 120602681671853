<template>
  <v-row no-gutters>
    <v-col>
      <v-stepper v-model="step">
        <v-stepper-header non-linear>
          <v-divider></v-divider>
          <v-stepper-step editable step="1">Customer</v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step editable step="2">Ways</v-stepper-step>
          <v-divider></v-divider>
        </v-stepper-header>
        <v-divider></v-divider>
        <v-stepper-items>
          <v-stepper-content step="1">
            <voucher-header :voucher="voucher" />
          </v-stepper-content>
          <v-stepper-content step="2">
            <voucher-detail :voucher="voucher" />
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-col>
  </v-row>
</template>
<script>
import VoucherHeader from "../components/VoucherHeader";
import VoucherDetail from "../components/VoucherBody";
import voucherService from "../service/VoucherService";

export default {
  mounted: function() {
    //console.log(this.$store.getters["TempVoucher/getVoucherList"]);
  },
  data() {
    return {
      voucher: {
        voucherId: 0,
        voucherCode: "",
        forPerson: [
          {
            name: "",
            pass: {
              passType: "", //ADULT,CHILD,INFANT
              passTypeName: ""
            }
          }
        ],
        toCustomer: {
          customerId: 0,
          customerName: "",
          phone: "",
          address: "",
          type: "AGENT"
        },
        pnr: "",
        date: "", //15 Dec 19
        way: [
          // {
          //   travelDate: "16 Dec", //16 Dec
          //   flight: {
          //     flightId: 5,
          //     flightName: "F0004"
          //   },
          //   className: "Low",
          //   dept: "11:10", //11:10
          //   fromSector: {
          //     sectorId: 1,
          //     sectorName: "MDL"
          //   },
          //   toSector: {
          //     sectorId: 2,
          //     sectorName: "YGN"
          //   },
          //   pass: 2,
          //   passType: "ADULT", //CHILD, INFANT
          //   fare: 5,
          //   baseFare: 3,
          //   commissionPercent: 0.0,
          //   commission: 0,
          //   usdTotalCommission: 0,
          //   mmkTotalCommission: 0,
          //   usdAmount: 10,
          //   mmkAmount: 0
          // }
        ],
        usdTotal: 0,
        mmkTotal: 0,
        usdReceive: 0,
        mmkReceive: 0,
        usdBalance: 0,
        mmkBalance: 0,
        mmkDiscount: 0,
        usdDiscount: 0,
        issuedBy: "",
        remark: ""
      },
      step: 0
    };
  },
  methods: {
    resetData: function() {
      let voucherId = this.$route.params.voucherId;
      this.voucher = {
        voucherId: 0,
        voucherCode: "",
        forPerson: [
          {
            name: "",
            pass: {
              passType: "", //ADULT,CHILD,INFANT
              passTypeName: ""
            }
          }
        ],
        toCustomer: {
          customerId: 0,
          customerName: "",
          phone: "",
          address: "",
          type: "AGENT"
        },
        pnr: "",
        date: "", //15 Dec 19
        way: [],
        usdTotal: 0,
        mmkTotal: 0,
        usdReceive: 0,
        mmkReceive: 0,
        usdBalance: 0,
        mmkBalance: 0,
        mmkDiscount: 0,
        usdDiscount: 0,
        issuedBy: "",
        remark: ""
      };
      if (voucherId != null && !isNaN(voucherId)) {
        
        voucherService.getVoucherId(voucherId).then(data => {
          console.log("Voucher data -----");
          console.log(data);
          let passCount = 0;
          data.way.forEach(value => {
            value.commissionPercent = Math.abs(value.commissionPercent);
            value.fare = Math.abs(value.fare);
            value.baseFare = Math.abs(value.baseFare);
            value.commissionPercent = Math.abs(value.commissionPercent);
            value.commission = Math.abs(value.commission);
            value.usdTotalCommission = Math.abs(value.usdTotalCommission);
            value.mmkTotalCommission = Math.abs(value.mmkTotalCommission);
            value.usdAmount = Math.abs(value.usdAmount);
            value.mmkAmount = Math.abs(value.mmkAmount);
            passCount += Number(value.pass);
          });
          data.usdTotal = Math.abs(data.usdTotal);
          data.mmkTotal = Math.abs(data.mmkTotal);
          data.usdReceive = Math.abs(data.usdReceive);
          data.mmkReceive = Math.abs(data.mmkReceive);
          data.usdBalance = Math.abs(data.usdBalance);
          data.mmkBalance = Math.abs(data.mmkBalance);
          data.mmkDiscount = Math.abs(data.mmkDiscount);
          data.usdDiscount = Math.abs(data.usdDiscount);
          data.totalPass = Math.abs(passCount);
          if (data.mmkDiscount > 0) {
            data.onePersonMMK = Number(data.mmkDiscount) / Number(passCount);
          }
          if (data.onePersonUsd > 0) {
            data.onePersonUsd = Number(data.usdDiscount) / Number(passCount);
          }
          this.voucher = data;
        });
      } else {
        console.log("Mounted Else");
        //(this.$route.params.voucherId)
        let storedVoucher = this.$store.getters["TempVoucher/getVoucherById"](
          this.$route.params.voucherId
        );

        console.log(storedVoucher);
        if (storedVoucher == null) {
          this.voucher.voucherCode = this.$route.params.voucherId;
          this.$store.commit("TempVoucher/addVoucher", this.voucher);
        } else {
          this.voucher = storedVoucher;
        }
      }
    }
  },
  computed: {
    autoSave: function() {
      if (this.voucher.voucherId > 0) {
        return false;
      }
      return true;
    }
  },
  components: {
    "voucher-header": VoucherHeader,
    "voucher-detail": VoucherDetail
  },
  watch: {
    voucher: {
      deep: true,
      handler: function(value) {
        console.log("Handle");

        if (this.autoSave) {
          this.$store.commit("TempVoucher/changeVoucher", value);
        }
      }
    },
    $route: {
      handler: "resetData",
      immediate: true,
      deep: true
    }
  }
};
</script>

<style scoped></style>
