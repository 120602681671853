<template>
  <v-row no-gutters>
    <v-col>
      <v-row no-gutters>
        <v-col cols="12" md="3" class="pb-5">
          <v-label>Search By</v-label>
          <v-radio-group row class="pl-3" v-model="searchBy">
            <v-radio value="phone" label="Phone"></v-radio>
            <v-radio value="customerName" label="Customer"></v-radio>
          </v-radio-group>
          <v-autocomplete
            dense
            autofocus
            clearable
            :loading="loading"
            :item-text="searchBy"
            :item-value="searchBy"
            :items="customers"
            hide-no-data
            return-object
            v-model="selectedCustomer"
            label="Search"
            :search-input.sync="search"
            cache-items
            single-line
            prepend-icon="search"
          >
            <template v-slot:selection="data">
              <v-chip
                color="primary"
                v-bind="data.attrs"
                :input-value="data.selected"
              >{{ data.item.customerName }}</v-chip>
            </template>
            <template v-slot:item="data">
              <template v-if="typeof data.item !== 'object'">
                <v-list-item-content v-text="data.item.customerName"></v-list-item-content>
              </template>
              <template v-else>
                <v-list-item-avatar>
                  <v-icon>stars</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title v-html="data.item.customerName"></v-list-item-title>
                  <v-list-item-subtitle v-html="data.item.phone"></v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </template>
          </v-autocomplete>
          <v-alert
            class="mt-2"
            border="left"
            dense
            text
            color="primary"
          >VoucherCode: {{ voucher.voucherCode }}</v-alert>
          <v-alert class="mt-5" border="left" dense text color="primary">Receipt Date</v-alert>
          <v-menu
            ref="menu"
            v-model="dateMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="voucher.date"
                label="Voucher Date *"
                prepend-icon="event"
                readonly
                :disabled="!isAdmin"
                v-on="on"
                required
              ></v-text-field>
            </template>
            <v-date-picker v-model="date">
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu = false">Cancel</v-btn>
              <v-btn text color="primary" @click="$refs.menu.save(date)">OK</v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" md="3" offset-md="1">
          <v-alert border="left" dense text color="primary">To Customer</v-alert>
          <v-chip outlined color="primary" dark>
            {{
            voucher.toCustomer.customerId == 0
            ? "New Customer"
            : +voucher.toCustomer.customerId
            }}
          </v-chip>
          <v-text-field
            :readonly="disableForm"
            label="Customer Name"
            v-model="voucher.toCustomer.customerName"
            prepend-icon="face"
          />
          <v-text-field
            label="Phone"
            v-model="voucher.toCustomer.phone"
            prepend-icon="phone"
            :readonly="disableForm"
          />
          <v-textarea
            rows="4"
            label="Address"
            v-model="voucher.toCustomer.address"
            prepend-icon="location_city"
            :readonly="disableForm"
          />
          <v-label>Type</v-label>
          <v-radio-group v-model="voucher.toCustomer.type" :readonly="disableForm">
            <v-radio label="Agent" value="AGENT"></v-radio>
            <v-radio label="Customer" value="CUSTOMER">Customer</v-radio>
          </v-radio-group>
        </v-col>
        <v-col cols="12" md="3" offset-md="1">
          <v-alert border="left" dense text color="primary">
            For
            <v-btn small text icon color="red">
              <v-icon @click="suggestion">assignment</v-icon>
            </v-btn>
          </v-alert>
          <v-row no-gutters>
            <v-col>
              <v-text-field
                type="number"
                label="Adult"
                v-model.number="voucher.adult"
                prepend-icon="person"
              />
            </v-col>
            <v-col>
              <v-text-field
                type="number"
                label="Child"
                v-model.number="voucher.child"
                prepend-icon="face"
              />
            </v-col>
            <v-col>
              <v-text-field
                type="number"
                label="Infant"
                v-model.number="voucher.infant"
                prepend-icon="child_care"
              />
            </v-col>
          </v-row>
          <template v-for="(person, index) in voucher.forPerson">
            <v-row no-gutters :key="index">
              <v-col cols="2" class="pt-5">
                {{
                voucher.forPerson[index].type
                }}
              </v-col>
              <v-col cols="4">
                <v-autocomplete
                  v-if="voucher.forPerson[index].type == 'Child'"
                  label="PaxType"
                  v-model="voucher.forPerson[index].pass"
                  :items="listChild"
                  return-object
                  item-text="passTypeName"
                  item-value="passTypeName"
                />
                <v-autocomplete
                  v-else-if="voucher.forPerson[index].type == 'Infant'"
                  label="PaxType"
                  v-model="voucher.forPerson[index].pass"
                  :items="listInfant"
                  return-object
                  item-text="passTypeName"
                  item-value="passTypeName"
                />
                <v-autocomplete
                  v-else
                  label="PaxType"
                  v-model="voucher.forPerson[index].pass"
                  :items="listAdult"
                  return-object
                  item-text="passTypeName"
                  item-value="passTypeName"
                />
              </v-col>
              <v-col cols="6">
                <v-text-field label="Name" v-model="person.name"></v-text-field>
              </v-col>
              <v-col cols="2" hidden>
                <v-btn
                  v-if="index != 0"
                  class="mt-2 ml-2"
                  text
                  icon
                  color="red"
                  @click="removeFor(person, index)"
                >
                  <v-icon>clear</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </template>
          <v-row no-gutters>
            <v-col>
              <v-btn hidden text color="primary" @click="addNewFor">
                Add New
                <v-icon>add</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-dialog v-model="forSuggestion">
          <v-card>
            <v-card-title>
              Related Suggestion
              <v-btn text disabled>
                <v-icon>file_copy</v-icon>Select Name to Copy
              </v-btn>
              <v-spacer />
              <v-btn icon @click="forSuggestion = false">
                <v-icon>close</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="6" md="3" v-for="(person, index) in listPerson" :key="index">
                  <v-card class="mx-auto" max-width="344" shaped>
                    <v-card-title>
                      <v-text-field
                        type="text"
                        solo
                        flat
                        dense
                        hide-details
                        v-model="person.customerName"
                        @select="selected"
                        class="plainText"
                      />
                    </v-card-title>
                    <v-card-text>
                      Phone - {{ person.phone }}
                      <br />
                      NRC - {{ person.nrc }}
                      <br />
                      Passport - {{ person.passport }}
                      <br />
                      {{ person.address }}
                      {{ person.dob }}
                      <br />
                      {{ person.remark }}
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
import customerService from "../service/CustomerService";

export default {
  mounted: function() {
    if (this.voucher.date == null || this.voucher.date == "") {
      this.voucher.date = this.$moment().format("DD MMM YY");
    }
    customerService.getGenderType().then(data => {
      this.listPassType.push(...data);
    });

    console.log(this.$store.state.userAccount.role);
    if (this.$store.state.userAccount.role == "ROLE_ADMIN") {
      this.isAdmin = true;
    } else {
      this.isAdmin = false;
    }
  },
  data() {
    return {
      // customers: [
      //   {
      //     customerId: 0,
      //     customerName: "Mg Mg",
      //     phone: "0998223",
      //     address: "Mandalay",
      //     type: "Agent"
      //   }
      // ],
      customers: [],
      selectedCustomer: {
        customerId: 0,
        customerName: "",
        phone: "",
        address: "",
        type: "AGENT"
      },
      searchBy: "phone",
      loading: false,
      search: null,
      listPassType: [],
      selectedPassType: {
        passTypeName: "",
        passType: ""
      },
      dateMenu: false,
      forSuggestion: false,
      listPerson: [],
      isAdmin: false
    };
  },
  props: {
    voucher: {
      type: Object,
      default: () => {
        return {
          adult: 0,
          child: 0,
          infant: 0,
          forPerson: {
            type: Array,
            default: () => {
              return {
                name: String,
                pass: {
                  type: Object,
                  default: {
                    passType: String,
                    passTypeName: String
                  }
                }
              };
            }
          },
          toCustomer: {
            customerId: {
              type: Number,
              default: 0
            },
            customerName: String,
            phone: String,
            address: String,
            type: String
          },
          date: String
        };
      }
    }
  },
  methods: {
    searchingData: function(text) {
      if (text.trim() == "") {
        return;
      }
      this.loading = true;
      customerService.getCustomerBy(text, this.searchBy).then(data => {
        this.customers.splice(0,this.customers.length);
        this.customers.push(...data);
        this.loading = false;
      });
    },
    addNewFor: function() {
      this.voucher.forPerson.push({
        name: "",
        pass: {
          passType: "",
          passTypeName: ""
        }
      });
    },
    removeFor: function(person, index) {
      this.$delete(this.voucher.forPerson, index);
    },
    suggestion: function() {
      this.listPerson = this.listPerson.filter(f => false);
      customerService
        .getRelativeByCustomer(this.selectedCustomer.customerId)
        .then(data => {
          this.listPerson.push(...data);
          this.forSuggestion = true;
        });
    },
    selected: function() {
      document.execCommand("copy");
    }
  },
  computed: {
    disableForm: function() {
      if (this.voucher.toCustomer.customerId == 0) {
        return false;
      }
      return true;
    },
    date: {
      set(value) {
        console.log(value);
        this.voucher.date = this.$moment(
          this.$moment(value, "YYYY-MM-DD")
        ).format("DD MMM YY");
      },
      get() {
        if (this.voucher != null && this.voucher.date == "") {
          return this.voucher.date;
        }
        return this.$moment(
          this.$moment(this.voucher.date, "DD MMM YY")
        ).format("YYYY-MM-DD");
      }
    },
    totalPassSize: function() {
      return this.voucher.adult + this.voucher.child + this.voucher.infant;
    },
    listAdult: function() {
      return this.listPassType.filter(v => {
        if (v.passType == "ADULT") return true;
        return false;
      });
    },
    listChild: function() {
      return this.listPassType.filter(v => {
        if (v.passType == "CHILD") return true;
        return false;
      });
    },
    listInfant: function() {
      return this.listPassType.filter(v => {
        if (v.passType == "INFANT") return true;
        return false;
      });
    }
  },
  watch: {
    search(val) {
      this.searchingData(val);//val && val !== this.select && 
    },
    selectedCustomer: function(value) {
      if (value == null || value.customerId == 0) {
        this.voucher.toCustomer = {
          customerId: 0,
          customerName: "",
          phone: "",
          address: "",
          type: "AGENT"
        };
      } else {
        this.voucher.toCustomer = value;
      }
    },
    totalPassSize: function(value) {
      let list = [];

      let adult = this.voucher.forPerson.filter(v => {
        if (v.pass.passType == "ADULT") return true;
        return false;
      });

      let child = this.voucher.forPerson.filter(v => {
        if (v.pass.passType == "CHILD") return true;
        return false;
      });
      let infant = this.voucher.forPerson.filter(v => {
        if (v.pass.passType == "INFANT") return true;
        return false;
      });
      for (let i = 0; i < this.voucher.adult; i++) {
        let obj = {
          name: "",
          pass: { passTypeName: "", passType: "" },
          type: "Adult"
        };
        let origin = adult[i];
        if (origin != null) {
          obj.name = origin.name;
          obj.pass = JSON.parse(JSON.stringify(origin.pass));
        }
        list.push(obj);
      }
      for (let i = 0; i < this.voucher.child; i++) {
        let obj = {
          name: "",
          pass: { passTypeName: "", passType: "" },
          type: "Child"
        };
        let origin = child[i];
        if (origin != null) {
          obj.name = origin.name;
          obj.pass = JSON.parse(JSON.stringify(origin.pass));
        }
        list.push(obj);
      }
      for (let i = 0; i < this.voucher.infant; i++) {
        let obj = {
          name: "",
          pass: { passTypeName: "", passType: "" },
          type: "Infant"
        };
        let origin = infant[i];
        if (origin != null) {
          obj.name = origin.name;
          obj.pass = JSON.parse(JSON.stringify(origin.pass));
        }
        list.push(obj);
      }
      console.log(value);
      this.voucher.forPerson = this.voucher.forPerson.filter(f => false);
      this.voucher.forPerson.push(...list);
    }
  }
};
</script>

<style scoped></style>
