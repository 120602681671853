import BasicService from "./BasicService";

class Setting extends BasicService {
  constructor() {
    super();
  }

  //   sectors
  deleteSector(sectors) {
    let url = `/sectors/${sectors.sectorId}`;
    return this.axios.delete(url, sectors).then(request => request.data);
  }
  updateSector(sectors) {
    let url = `/sectors/${sectors.sectorId}`;
    return this.axios.put(url, sectors).then(request => request.data);
  }
  addSector(sectors) {
    let url = `/sectors`;
    return this.axios.post(url, sectors).then(request => request.data);
  }
  getSector() {
    let url = `/sectors`;
    return this.axios.get(url).then(request => request.data);
  }

  //   paxer
  deletePaxers(paxer) {
    let url = `/passtypes/${paxer.passTypeId}`;
    return this.axios.delete(url, paxer).then(request => request.data);
  }
  updatePaxers(paxer) {
    let url = `/passtypes/${paxer.passTypeId}`;
    return this.axios.put(url, paxer).then(request => request.data);
  }
  addPaxers(paxer) {
    let url = `/passtypes`;
    return this.axios.post(url, paxer).then(request => request.data);
  }
  getPaxers() {
    let url = `/passtypes`;
    return this.axios.get(url).then(request => request.data);
  }

  //   classes
  deleteClasses(classes) {
    let url = `/classes/${classes.classId}`;
    return this.axios.delete(url, classes).then(request => request.data);
  }
  updateClasses(classes) {
    let url = `/classes/${classes.classId}`;
    return this.axios.put(url, classes).then(request => request.data);
  }
  addClasses(classes) {
    let url = `/classes`;
    return this.axios.post(url, classes).then(request => request.data);
  }
  getClasses() {
    let url = `/classes`;
    return this.axios.get(url).then(request => request.data);
  }
}

const SettingService = new Setting();
export default SettingService;