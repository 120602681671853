<template>
  <v-dialog
    :value="dialog"
    v-on:input="updateValue($event)"
    max-width="70vW"
    persistent
    scrollable
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar dark color="primary" dense>
        <v-toolbar-title>Other Charges</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="closeDialog">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-row no-gutters>
          <v-col cols="12" md="4" offset-md="1">
            <v-radio-group v-model="way.wayStatus" @change="changeNote">
              <v-radio label="Overweight Fee" value="WEIGHT"></v-radio>
              <v-radio label="Wheelchair Service" value="WHEEL"></v-radio>
              <v-radio label="Other Service" value="OTHER"></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="12" md="2" offset-md="1" class="mt-md-3">
            <v-alert
              border="left"
              colored-border
              color="primary accent-4"
              elevation="2"
              dense
            >From: {{way.fromSector.shortName}}</v-alert>
          </v-col>
          <v-col cols="12" md="2" class="mt-md-3 ml-md-2">
            <v-alert
              border="left"
              colored-border
              color="primary accent-4"
              elevation="2"
              dense
            >To: {{way.toSector.shortName}}</v-alert>
          </v-col>

          <v-col cols="12" md="4" offset-md="1">
            <v-radio-group label="Cash" v-model="moneyType" prepend-icon="money" row>
              <v-radio label="MMK" value="mmk"></v-radio>
              <v-radio label="USD" value="usd"></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="12" md="4" offset-md="1">
            <v-checkbox label="Round Trip" v-model="way.round" />
          </v-col>
          <v-col cols="12" md="4" offset-md="1">
            <v-row>
              <v-col>
                <v-text-field
                  label="Pax"
                  name="pass"
                  dense
                  v-model.number="way.pass"
                  type="number"
                />
              </v-col>
              <v-col>
                <v-text-field
                  label="Fare"
                  name="fare"
                  dense
                  v-model.number="way.fare"
                  type="number"
                />
              </v-col>
            </v-row>
            <v-text-field label="USD Amount" dense v-model.number="way.usdAmount" readonly />
            <v-text-field label="MMK Amount" dense v-model.number="way.mmkAmount" readonly />
          </v-col>

          <v-col cols="12" md="4" offset-md="1">
            <v-textarea v-model="way.description" label="Note" rows="8" outlined></v-textarea>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-col cols="12" md="4" offset-md="1" class="pt-4">
          <v-btn v-if="way.wayId==0" text color="primary" @click="saveWay(way)">
            <v-icon>save</v-icon>Save
          </v-btn>
          <v-btn v-else text color="primary" @click="updateWay(way)">
            <v-icon>update</v-icon>Update
          </v-btn>
        </v-col>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  data() {
    return {
      from: [],
      to: [],
      listSector: [],
      moneyType: "mmk"
    };
  },
  props: {
    dialog: {
      type: Boolean
    },
    way: {
      wayId: Number,
      travelDate: "", //16 Dec
      flight: {
        flightId: 0,
        flightName: ""
      },
      className: "",
      dept: "", //11:10
      fromSector: {
        sectorId: 0,
        sectorName: ""
      },
      toSector: {
        sectorId: 0,
        sectorName: ""
      },
      pass: Number,
      passType: String, //ADULT, CHILD, INFANT
      fare: Number,
      usdAmount: Number,
      mmkAmount: Number,
      description: String,
      round: Boolean,
      wayStatus: String //WEIGHT, WHEEL
    },
    saveWay: Function,
    updateWay: Function
  },
  methods: {
    closeDialog: function() {
      this.$emit("update:dialog", false);
    },
    calculateTotal: function() {
      let result = 0;

      result += this.way.fare * this.way.pass;
      if (this.moneyType == "mmk") {
        //result -= this.way.mmkTotalCommission;
        this.way.mmkAmount = result;
        this.way.usdAmount = 0;
      } else {
        //result -= this.way.usdTotalCommission;
        this.way.mmkAmount = 0;
        this.way.usdAmount = result;
      }
    },
    changeNote: function(value){
      
         let result = '';
      if(value == 'WEIGHT'){
        result = 'Overweight Fee'
      }else if(value == 'WHEEL'){
        result = 'WheeelChair Service'
      }else if(value == 'OTHER'){
        result = 'Other Charges'
      }
      this.way.description = result;
    }
  },
  watch: {
    dialog: function() {
      if (this.way.wayId != 0) {
        if (this.way.mmkAmount > 0) {
          this.moneyType = "mmk";
        } else {
          this.moneyType = "usd";
        }
      }
    },
    "way.pass": function(value) {
      this.calculateTotal();
    },
    "way.fare": function(value) {
      this.calculateTotal();
    },
    moneyType: function(value) {
      let result = 0;
      result += this.way.commission * this.way.pass;
      if (this.moneyType == "mmk") {
        this.way.mmkTotalCommission = result;
        this.way.usdTotalCommission = 0;
      } else {
        this.way.mmkTotalCommission = 0;
        this.way.usdTotalCommission = result;
      }
      this.calculateTotal();
    }
  }
};
</script>

<style>
</style>