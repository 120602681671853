<template>
  <v-dialog
    :value="dialog"
    v-on:input="updateValue($event)"
    max-width="100vW"
    persistent
    scrollable
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar dark color="primary">
        <v-toolbar-title>Way</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="closeDialog">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <v-row no-gutters>
          <v-col cols="12">
            <v-row no-gutters>
              <v-col cols="12" md="3">
                <v-menu
                  v-model="dateMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="way[0].travelDate"
                      label="TravelDate *"
                      prepend-icon="event"
                      readonly
                      v-on="on"
                      required
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="date" @input="dateMenu = false"></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="2" offset-md="1">
                <v-text-field
                  v-model="way[0].dept"
                  label="Time"
                  prepend-icon="access_time"
                  v-mask="mask"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" md="3">
                <v-autocomplete
                  label="Airline"
                  prepend-icon="local_airport"
                  :items="listAirline"
                  item-text="airlineName"
                  item-value="airlineId"
                  v-model="selectedAirline"
                  return-object
                />
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" md="3">
                <v-autocomplete
                  label="Flight"
                  prepend-icon="flight"
                  :items="listFlight"
                  item-text="flightName"
                  item-value="flightName"
                  v-model="way[0].flight"
                  return-object
                />
              </v-col>
              <v-col cols="12" md="2" offset-md="1">
                <v-autocomplete
                  label="From"
                  prepend-icon="trending_up"
                  :items="from"
                  item-text="sectorName"
                  v-model="way[0].fromSector"
                  return-object
                >
                  <template v-slot:item="data">
                    <template>
                      <v-list-item-content>
                        <v-list-item-title v-html="data.item.sectorName"></v-list-item-title>
                        <v-list-item-subtitle v-html="data.item.shortName"></v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="12" md="2" class="ml-md-2">
                <v-autocomplete
                  label="To"
                  prepend-icon="trending_down"
                  :items="to"
                  item-text="sectorName"
                  v-model="way[0].toSector"
                  return-object
                >
                  <template v-slot:item="data">
                    <template>
                      <v-list-item-content>
                        <v-list-item-title v-html="data.item.sectorName"></v-list-item-title>
                        <v-list-item-subtitle v-html="data.item.shortName"></v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" md="3">
                <v-autocomplete
                  label="Class"
                  prepend-icon="category"
                  :items="listClass"
                  item-text="className"
                  item-value="className"
                  v-model="way[0].className"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3" md="3">
                <v-alert type="success">Adult: {{ adult }}, Child: {{ child }}, Infant {{ infant }}</v-alert>
              </v-col>
              <v-col cols="3" md="1" v-if="!isUpdate">
                <v-text-field v-model="selectAdult"  label="Adult" />
              </v-col>
              <v-col cols="3" md="1" v-if="!isUpdate">
                <v-text-field v-model="selectChild"  label="Child" />
              </v-col>
              <v-col cols="3" md="1" v-if="!isUpdate">
                <v-text-field
                  v-model="selectInfant"
                  label="Infant"
                />
              </v-col>
            </v-row>
            <v-expansion-panels v-model="open">
              <v-expansion-panel v-for="(w, index) in way" :key="index">
                <v-expansion-panel-header>
                  Way {{ index + 1 }} , {{ w.passType }}
                  {{ w.pass }}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <calculate-way
                    :way="w"
                    :adult="adult"
                    :child="child"
                    :infant="infant"
                    :isUpdate="isUpdate"
                    :dialog.sync="dialog"
                  />
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <v-row no-gutters>
              <v-col cols="12" md="3" class="pt-4">
                <v-btn v-if="way[0].wayId == 0" block color="primary" @click="saveWay(way)">
                  <v-icon>save</v-icon>Save
                </v-btn>
                <v-btn v-else block color="primary" @click="updateWay(way[0])">
                  <v-icon>update</v-icon>Update
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import airlineService from "../service/AirlineService";
import flightService from "../service/FlightService";
import sectorService from "../service/SectorService";
import settingService from "../service/SettingService";
import CalculateWay from "../components/CalculateWay";
import { mask } from "vue-the-mask";

export default {
  directives: {
    mask
  },
  mounted: function() {},
  data() {
    return {
      listAirline: [],
      listFlight: [],
      listClass: [],
      listSector: [],

      selectedAirline: {
        airlineId: 0,
        airlineName: ""
      },
      //   selectedFlight: {
      //     flightId: 0,
      //     flightName: ""
      //   },
      //   selectedClass: {},
      dateMenu: false,
      timeMenu: false,
      moneyType: "mmk",
      from: [],
      to: [],
      mask: "##:##",
      open: 0,
      selectAdult: 0,
      selectChild: 0,
      selectInfant: 0
    };
  },
  props: {
    dialog: {
      type: Boolean
    },
    // way: {
    //   type: Object,
    //   default: () => {
    //     return {
    //       travelDate: "", //16 Dec
    //       flight: {
    //         flightId: 0,
    //         flightName: ""
    //       },
    //       className: "",
    //       dept: "", //11:10
    //       fromSector: {
    //         sectorId: 0,
    //         sectorName: "",
    //         shortName: ""
    //       },
    //       toSector: {
    //         sectorId: 0,
    //         sectorName: ""
    //       },
    //       pass: 0,
    //       passType: "ADULT", //CHILD, INFANT
    //       fare: 0,
    //       baseFare: 0,
    //       commissionPercent: 0,
    //       commission: 0,
    //       usdTotalCommission: 0,
    //       mmkTotalCommission: 0,
    //       usdAmount: 0,
    //       mmkAmount: 0
    //     };
    //   }
    // },
    way: {
      type: Array,
      default: () => {
        return [
          {
            travelDate: "", //16 Dec
            flight: {
              flightId: 0,
              flightName: ""
            },
            className: "",
            dept: "", //11:10
            fromSector: {
              sectorId: 0,
              sectorName: "",
              shortName: ""
            },
            toSector: {
              sectorId: 0,
              sectorName: ""
            },
            pass: 0,
            passType: "ADULT", //CHILD, INFANT
            fare: 0,
            baseFare: 0,
            commissionPercent: 0,
            commission: 0,
            usdTotalCommission: 0,
            mmkTotalCommission: 0,
            usdAmount: 0,
            mmkAmount: 0
          }
        ];
      }
    },
    adult: Number,
    child: Number,
    infant: Number,
    forPerson: Array,
    saveWay: Function,
    updateWay: Function,
    roundTrip: Boolean
  },
  methods: {
    calculate: function() {
      this.way.commission =
        this.way.commissionPercent * this.way.baseFare * 0.01;
      if (this.moneyType == "mmk") {
        this.way.mmkTotalCommission = this.way.commission * this.way.pass;
        this.way.usdTotalCommission = 0;
        this.way.mmkAmount = this.way.fare * this.way.pass;
        this.way.usdAmount = 0;
      } else {
        this.way.usdTotalCommission = this.way.commission * this.way.pass;
        this.way.mmkTotalCommission = 0;
        this.way.usdAmount = this.way.fare * this.way.pass;
        this.way.mmkAmount = 0;
      }
    },
    changePass: function() {
      // if (this.way.passType == "ADULT") {
      //   this.way.pass = this.adult;
      // } else if (this.way.passType == "CHILD") {
      //   this.way.pass = this.child;
      // } else {
      //   this.way.pass = this.infant;
      // }
    },
    changeToSector: function() {
      this.from = this.from.filter(f => false);
      this.from.push(
        ...this.listSector.filter(data => {
          if (this.way[0].toSector.sectorId == data.sectorId) {
            return false;
          }
          return true;
        })
      );
    },
    changeFromSector: function() {
      this.to = this.to.filter(f => false);
      this.to.push(
        ...this.listSector.filter(data => {
          if (this.way[0].fromSector.sectorId == data.sectorId) {
            return false;
          }
          return true;
        })
      );
    },
    addPersonCount: function({ adult, child, infant }) {
      this.selectAdult = adult;
      this.selectChild = child;
      this.selectInfant = infant;
    },
    closeDialog: function() {
      this.$emit("update:roundTrip", false);
      this.$emit("update:dialog", false);
    }
  },
  computed: {
    date: {
      set(value) {
        this.way[0].travelDate = this.$moment(
          this.$moment(value, "YYYY-MM-DD")
        ).format("DD MMM YY");
      },
      get() {
        if (this.way[0] != null && this.way[0].travelDate == "") {
          return this.way[0].travelDate;
        }
        return this.$moment(
          this.$moment(this.way[0].travelDate, "DD MMM YY")
        ).format("YYYY-MM-DD");
      }
    },
    totalPassSize: function() {
      return this.selectAdult + this.selectChild + this.selectInfant;
    },
    isUpdate: function() {
      return this.way[0].wayId != 0;
    }
  },
  watch: {
    dialog: function(value) {
      if (value) {
        // if (this.way.wayId != 0) {
        //   if (this.way.mmkAmount > 0) {
        //     this.moneyType = "mmk";
        //   } else {
        //     this.moneyType = "usd";
        //   }
        // }
        airlineService.getAllAirline().then(data => {
          this.listAirline = this.listAirline.filter(f => false);
          this.listAirline.push(...data);

          // if (this.way[0].wayId != 0) {
          //   this.selectedAirline.airlineId = this.way[0].flight.airline.airlineId;
          // }
          if (
            this.way[0].flight != null &&
            this.way[0].flight.airline != null
          ) {
            this.selectedAirline.airlineId = this.way[0].flight.airline.airlineId;
          }
          if (this.way[0].wayId == 0) {
            this.way[0].flight = {
              flightId: 0,
              flightName: ""
            };
          }
        });
        settingService.getClasses().then(data => {
          this.listClass = this.listClass.filter(f => false);
          this.listClass.push(...data);
        });
      }
    },
    "way.flight.airline.airlineId": function(value) {
      this.selectedAirline.airlineId = value;
    },
    "selectedAirline.airlineId": function(value) {
      if (value == null) {
        return;
      }
      sectorService.getAllSectorByAirlineId(value).then(data => {
        this.listSector = this.listSector.filter(f => false);
        this.listSector.push(...data);
        this.to = this.listSector;
        this.from = this.listSector;
      });
      flightService.getFlightByAirlineId(value).then(data => {
        this.listFlight = this.listFlight.filter(f => false);
        this.listFlight.push(...data);
      });
    },
    "way.toSector.sectorId": function() {},
    "way.fromSector.sectorId": function() {},
    moneyType: function() {
      this.calculate();
    },
    totalPassSize: function(value) {
      let way = [];
      let cloneObj = JSON.parse(JSON.stringify(this.way[0]));
      let adult = this.way.filter(v => v.passType == "ADULT");
      let child = this.way.filter(v => v.passType == "CHILD");
      let infant = this.way.filter(v => v.passType == "INFANT");
      if (this.selectAdult > 0) {
        if (adult.length != 0) {
          adult = adult[0];
          let obj = {
            wayId: cloneObj.wayId,
            travelDate: cloneObj.travelDate, //16 Dec
            flight: cloneObj.flight,
            className: cloneObj.className,
            dept: cloneObj.dept, //11:10
            fromSector: cloneObj.fromSector,
            toSector: cloneObj.toSector,
            pass: this.selectAdult,
            passType: "ADULT", //CHILD, INFANT
            fare: adult.fare,
            baseFare: adult.baseFare,
            commissionPercent: adult.commissionPercent,
            commission: adult.commission,
            usdTotalCommission: adult.usdTotalCommission,
            mmkTotalCommission: adult.mmkTotalCommission,
            usdAmount: adult.usdAmount,
            mmkAmount: adult.mmkAmount
          };
          way.push(obj);
        } else {
          let obj = {
            wayId: 0,
            travelDate: "", //16 Dec
            flight: {
              flightId: 0,
              flightName: ""
            },
            className: "",
            dept: "", //11:10
            fromSector: {
              sectorId: 0,
              sectorName: ""
            },
            toSector: {
              sectorId: 0,
              sectorName: ""
            },
            pass: this.selectAdult,
            passType: "ADULT", //CHILD, INFANT
            fare: 0,
            baseFare: 0,
            commissionPercent: 0,
            commission: 0,
            usdTotalCommission: 0,
            mmkTotalCommission: 0,
            usdAmount: 0,
            mmkAmount: 0
          };
          way.push(obj);
        }
      }
      if (this.selectChild > 0) {
        if (child.length != 0) {
          child = child[0];
          let obj = {
            wayId: cloneObj.wayId,
            travelDate: cloneObj.travelDate, //16 Dec
            flight: cloneObj.flight,
            className: cloneObj.className,
            dept: cloneObj.dept, //11:10
            fromSector: cloneObj.fromSector,
            toSector: cloneObj.toSector,
            pass: this.selectChild,
            passType: "CHILD", //CHILD, INFANT
            fare: child.fare,
            baseFare: child.baseFare,
            commissionPercent: child.commissionPercent,
            commission: child.commission,
            usdTotalCommission: child.usdTotalCommission,
            mmkTotalCommission: child.mmkTotalCommission,
            usdAmount: child.usdAmount,
            mmkAmount: child.mmkAmount
          };
          way.push(obj);
        } else {
          let obj = {
            wayId: cloneObj.wayId,
            travelDate: cloneObj.travelDate, //16 Dec
            flight: cloneObj.flight,
            className: cloneObj.className,
            dept: cloneObj.dept, //11:10
            fromSector: cloneObj.fromSector,
            toSector: cloneObj.toSector,
            pass: this.selectChild,
            passType: "CHILD", //CHILD, INFANT
            fare: 0,
            baseFare: 0,
            commissionPercent: 0,
            commission: 0,
            usdTotalCommission: 0,
            mmkTotalCommission: 0,
            usdAmount: 0,
            mmkAmount: 0
          };
          way.push(obj);
        }
      }
      if (this.selectInfant > 0) {
        if (infant.length != 0) {
          infant = infant[0];
          let obj = {
            wayId: 0,
            travelDate: cloneObj.travelDate, //16 Dec
            flight: cloneObj.flight,
            className: cloneObj.className,
            dept: cloneObj.dept, //11:10
            fromSector: cloneObj.fromSector,
            toSector: cloneObj.toSector,
            pass: this.selectInfant,
            passType: "INFANT", //CHILD, INFANT
            fare: infant.fare,
            baseFare: infant.baseFare,
            commissionPercent: infant.commissionPercent,
            commission: infant.commission,
            usdTotalCommission: infant.usdTotalCommission,
            mmkTotalCommission: infant.mmkTotalCommission,
            usdAmount: infant.usdAmount,
            mmkAmount: infant.mmkAmount
          };
          way.push(obj);
        } else {
          let obj = {
            wayId: cloneObj.wayId,
            travelDate: cloneObj.travelDate, //16 Dec
            flight: cloneObj.flight,
            className: cloneObj.className,
            dept: cloneObj.dept, //11:10
            fromSector: cloneObj.fromSector,
            toSector: cloneObj.toSector,
            pass: this.selectInfant,
            passType: "INFANT", //CHILD, INFANT
            fare: 0,
            baseFare: 0,
            commissionPercent: 0,
            commission: 0,
            usdTotalCommission: 0,
            mmkTotalCommission: 0,
            usdAmount: 0,
            mmkAmount: 0
          };
          way.push(obj);
        }
      }
      this.way.splice(0, this.way.length);
      if (way.length > 0) {
        console.log("Zero >");
        way[0].wayId = cloneObj.wayId;
        way[0].travelDate = cloneObj.travelDate; //16 Dec/
        way[0].flight = cloneObj.flight;

        way[0].className = cloneObj.className;
        way[0].dept = cloneObj.dept; //11:10
        way[0].fromSector = cloneObj.fromSector;
        way[0].toSector = cloneObj.toSector;
        //way[0].passType = cloneObj.passType; //CHILD, INFANT
      } else {
        console.log("Zero");
        way.push(cloneObj);
        cloneObj.pass = this.selectAdult.pass;
      }
      this.way.push(...way);
    }
  },
  components: {
    "calculate-way": CalculateWay
  }
};
</script>

<style scoped></style>
