<template>
  <v-row no-gutters>
    <v-col cols="12" md="8" class="mb-3">
      <v-data-table
        :mobile-breakpoint="NaN"
        :headers="headers"
        :items-per-page="-1"
        hide-default-footer
        hide-default-header
        height="60vh"
        :items="voucher.way"
        class="tbl-way"
      >
        <template v-slot:top>
          <v-btn block text color="primary" outlined class="mb-2" @click="addWay">
            Add Way
            <v-icon>add</v-icon>
          </v-btn>
        </template>
        <template v-slot:header="{ headers }">
          <thead>
            <tr>
              <th rowspan="2">Travel Date</th>
              <th rowspan="2">Flight</th>
              <th rowspan="2">Class</th>
              <th rowspan="2">Dep</th>
              <th rowspan="2">From</th>
              <th rowspan="2">To</th>
              <th rowspan="2">Pax</th>
              <th rowspan="2">Fare</th>
              <th colspan="2">Amout</th>
              <th rowspan="2">Action</th>
            </tr>
            <tr>
              <th>MMK</th>
              <th>USD</th>
            </tr>
          </thead>
        </template>
        <template v-slot:body="{ items }" v-if="voucher.way != null && voucher.way.length > 0">
          <tbody>
            <template v-for="(item, index) in items">
              <template v-if="item.status == 'INACTIVE'"></template>

              <template v-else-if="item.wayStatus == 'WEIGHT' || item.wayStatus == 'WHEEL' || item.wayStatus == 'OTHER'">
                <tr :key="(index + 1) * 1000">
                  <td>{{ item.travelDate }}</td>
                  <td colspan="3">{{item.description}} {{item.round?"(Round Trip)":""}}</td>
                  <td>{{ item.fromSector.sectorName }}</td>
                  <td>{{ item.toSector.sectorName }}</td>
                  <td>{{ item.pass }}</td>
                  <td>{{ item.fare | zeroFilter }}</td>
                  <td>{{ item.mmkAmount | zeroFilter }}</td>
                  <td>{{ item.usdAmount | zeroFilter }}</td>
                  <td>
                    <v-btn
                      text
                      icon
                      small
                      color="primary"
                      class="ml-2 mr-2"
                      @click="editOther(item)"
                    >
                      <v-icon>edit</v-icon>
                    </v-btn>
                    <v-btn text icon small color="red" @click="deleteWay(item)">
                      <v-icon>clear</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </template>
              <template v-else>
                <tr :key="index + 1">
                  <td>{{ item.travelDate }}</td>
                  <td>{{ item.flight.flightName }}</td>
                  <td>{{ item.className }}</td>
                  <td>{{ item.dept }}</td>
                  <td>{{ item.fromSector.sectorName }}</td>
                  <td>{{ item.toSector.sectorName }}</td>
                  <td>{{ item.pass }}</td>
                  <td>{{ item.fare }}</td>
                  <td>{{ item.mmkAmount | zeroFilter }}</td>
                  <td>{{ item.usdAmount | zeroFilter }}</td>
                  <td>
                    <v-btn text icon small color="primary" class="ml-2 mr-2" @click="editWay(item)">
                      <v-icon>edit</v-icon>
                    </v-btn>
                    <v-btn
                      icon
                      color="warning"
                      class="mr-2"
                      @click="roundTripWay(item)"
                      v-if="item.roundTripId == null"
                    >
                      <v-icon>swap_horizontal_circle</v-icon>
                    </v-btn>
                    <v-btn
                      text
                      icon
                      small
                      color="blue"
                      @click="addOther(item)"
                      v-if="item.travelDate != '' && item.flight.flightId !=0 && item.dept !='' && item.fromSector.sectorId!=0 && item.toSector.sectorId!=0 && item.className!=''"
                    >
                      <v-icon>add_shopping_cart</v-icon>
                    </v-btn>
                    <v-btn text icon small color="red" @click="deleteWay(item)">
                      <v-icon>clear</v-icon>
                    </v-btn>
                  </td>
                </tr>
                <tr :key="(index + 1) * 100" v-if="item.commission != 0" class="row-shadow">
                  <td colspan="4"></td>
                  <td colspan="2">Commission</td>
                  <td>{{ item.pass }}</td>
                  <td>{{ item.commission | zeroMinusFilter }}</td>
                  <td>{{ item.mmkTotalCommission | zeroMinusFilter }}</td>
                  <td>{{ item.usdTotalCommission | zeroMinusFilter }}</td>
                  <td>
                    <v-btn text icon small color="primary" class="ml-2 mr-2" @click="editWay(item)">
                      <v-icon>edit</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </template>
            </template>
          </tbody>
        </template>
      </v-data-table>
    </v-col>

    <v-col cols="12" md="4" class="pl-4">
      <v-row
        no-gutters
        style="border: 1px solid rgba(0, 0, 0, 0.12);"
        v-if="voucher.toCustomer.type != 'AGENT'"
      >
        <v-col cols="4" md="4" class="pt-6 pl-2">
          <v-label>Cashback</v-label>
        </v-col>
        <v-col cols="8" md="4" class="pa-1">
          <v-text-field label="MMK" v-model.number="voucher.mmkCashback" />
        </v-col>
        <v-col cols="4" md="4" class="pa-1">
          <v-text-field label="USD" v-model.number="voucher.usdCashback" />
        </v-col>
      </v-row>
      <v-row
        no-gutters
        style="border: 1px solid rgba(0, 0, 0, 0.12);"
        v-if="voucher.toCustomer.type != 'AGENT'"
      >
        <v-col cols="4" md="4" class="pt-6 pl-2">
          <v-label>Discount</v-label>
        </v-col>
        <v-col cols="2" md="2" class="pa-1">
          <v-text-field label="Pass" v-model.number="voucher.totalPass" hide-details />
        </v-col>
        <v-col cols="3" md="3" class="pa-1">
          <v-text-field label="MMK" v-model.number="voucher.onePersonMMK" hide-details />
        </v-col>
        <v-col cols="3" md="3" class="pa-1">
          <v-text-field label="Usd" v-model.number="voucher.onePersonUsd" hide-details />
        </v-col>
        <v-col cols="4" md="4" class="pt-6 pl-2"></v-col>
        <v-col cols="4" md="4" class="pa-1">
          <v-text-field label="Total MMK" v-model.number="voucher.mmkDiscount" disabled />
        </v-col>
        <v-col cols="4" md="4" class="pa-1">
          <v-text-field label="Total USD" v-model.number="voucher.usdDiscount" disabled />
        </v-col>
      </v-row>
      <v-row no-gutters style="border: 1px solid rgba(0, 0, 0, 0.12);">
        <v-col cols="4" md="4" class="pt-6 pl-2">
          <v-label>Total Amount</v-label>
        </v-col>
        <v-col cols="4" md="4" class="pa-1">
          <v-text-field label="MMK" v-model.number="voucher.mmkTotal" readonly />
        </v-col>
        <v-col cols="4" md="4" class="pa-1">
          <v-text-field label="USD" v-model.number="voucher.usdTotal" readonly />
        </v-col>
      </v-row>
      <v-row no-gutters style="border: 1px solid rgba(0, 0, 0, 0.12);" class="mt-1">
        <v-col cols="4" md="4" class="pt-5 pl-2">
          <v-label>
            Received
            <v-btn icon color="primary" @click="convert">
              <v-icon>swap_vert</v-icon>
            </v-btn>
          </v-label>
        </v-col>
        <v-col cols="4" md="4" class="pa-1">
          <v-text-field label="MMK" v-model.number="voucher.mmkReceive" />
        </v-col>
        <v-col cols="4" md="4" class="pa-1">
          <v-text-field label="USD" v-model.number="voucher.usdReceive" />
        </v-col>
      </v-row>
      <v-row no-gutters style="border: 1px solid rgba(0, 0, 0, 0.12);" class="mt-1">
        <v-col cols="4" md="4" class="pt-6 pl-2">
          <v-label>Balance</v-label>
        </v-col>
        <v-col cols="4" md="4" class="pa-1">
          <v-text-field label="MMK" v-model.number="voucher.mmkBalance" readonly />
        </v-col>
        <v-col cols="4" md="4" class="pa-1">
          <v-text-field label="USD" v-model.number="voucher.usdBalance" readonly />
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="6">
          <v-text-field
            dense
            outlined
            label="Issued by:"
            disabled
            v-model="voucher.issuedBy"
            class="pa-1"
            hide-details
          />
        </v-col>
        <v-col cols="6">
          <v-text-field dense outlined label="PNR" v-model="voucher.pnr" class="pa-1" hide-details />
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-textarea dense outlined rows="3" label="Remark:" v-model="voucher.remark" class="pa-1" />
      </v-row>
    </v-col>
    <v-col cols="12" md="4" offset-md="2">
      <v-btn
        v-if="voucher.voucherId <= 0"
        @click="saveVoucher"
        :loading="loading"
        class="primary mb-2"
        block
        text
      >
        <v-icon>save</v-icon>Save Voucher
      </v-btn>
      <v-btn :loading="loading" v-else text block class="primary mb-2" @click="updateVoucher">
        <v-icon>update</v-icon>Update Voucher
      </v-btn>
    </v-col>
    <way-dialog
      ref="wayDialog"
      :dialog.sync="wayDialog"
      :way="way"
      :forPerson="voucher.forPerson"
      :adult="voucher.adult"
      :child="voucher.child"
      :infant="voucher.infant"
      :saveWay="saveWay"
      :updateWay="updateWay"
      :roundTrip.sync="roundTrip"
    />
    <v-dialog v-model="convertionDialog" max-width="500">
      <v-card>
        <v-card-title>Conversion</v-card-title>
        <v-card-text>
          <v-text-field label="Receive Amount MMK" v-model.number="conversionAmt" />
          <v-text-field label="Conversion Rate" v-model.number="conversionRate" />
          <v-text-field label="Result" v-model.number="result" />
          <v-btn text block color="primary" @click="writeConversion">Conversion write as remark</v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
    <weight-or-wheel-dialog
      ref="weightOrWheelDialog"
      :way="otherWay"
      :dialog.sync="otherDialog"
      :saveWay="saveOtherCharges"
      :updateWay="updateOtherCharges"
    />
  </v-row>
</template>

<script>
import WayDialog from "../components/WayDialog";
import WeightOrWheelDialog from "../components/WeightOrWheelDialog";
import voucherService from "../service/VoucherService";

export default {
  data() {
    return {
      loading: false,
      localId: -1,
      headers: [
        {
          text: "Travel Date",
          value: "travelDate"
        },
        {
          text: "Flight",
          value: "flight.flightName"
        },
        {
          text: "Class",
          value: "className"
        },
        {
          text: "Dep",
          value: "dept"
        },
        {
          text: "From",
          value: "fromSector.sectorName"
        },
        {
          text: "To",
          value: "toSector.sectorName"
        },
        {
          text: "Pass",
          value: "pass"
        },
        {
          text: "Fare",
          value: "fare"
        },
        {
          text: "MMK",
          value: "mmkAmount"
        },
        {
          text: "USD",
          value: "usdAmount"
        },
        {
          text: "Action",
          value: "action"
        }
      ],
      wayDialog: false,
      way: [
        {
          wayId: 0,
          travelDate: "", //16 Dec
          flight: {
            flightId: 0,
            flightName: ""
          },
          className: "",
          dept: "", //11:10
          fromSector: {
            sectorId: 0,
            sectorName: ""
          },
          toSector: {
            sectorId: 0,
            sectorName: ""
          },
          pass: 0,
          passType: "ADULT", //CHILD, INFANT
          fare: 0,
          baseFare: 0,
          commissionPercent: 0,
          commission: 0,
          usdTotalCommission: 0,
          mmkTotalCommission: 0,
          usdAmount: 0,
          mmkAmount: 0,
          usdCashback: 0,
          mmkCashback: 0
        }
      ],
      convertionDialog: false,
      conversionAmt: 0,
      conversionRate: 0,
      roundTrip: false,
      roundTripData: null,
      otherDialog: false,
      otherWay: {
        wayId: 0,
        travelDate: "", //16 Dec
        flight: {
          flightId: 0,
          flightName: ""
        },
        className: "",
        dept: "", //11:10
        fromSector: {
          sectorId: 0,
          sectorName: ""
        },
        toSector: {
          sectorId: 0,
          sectorName: ""
        },
        pass: 0,
        passType: "ADULT", //ADULT, CHILD, INFANT
        fare: 0,
        usdAmount: 0,
        mmkAmount: 0,
        description: "",
        round: false,
        wayStatus: "WEIGHT" //WEIGHT, WHEEL
      }
    };
  },
  props: {
    voucher: {
      adult: Number,
      child: Number,
      infant: Number,
      pnr: String,
      usdTotal: Number,
      mmkTotal: Number,
      usdReceive: Number,
      mmkReceive: Number,
      usdBalance: Number,
      mmkBalance: Number,
      mmkDiscount: Number,
      usdDiscount: Number,
      mmkCashback: Number,
      usdCashback: Number,
      totalPass: 0,
      onePersonMMK: 0,
      onePersonUsd: 0,
      way: {
        type: Array,
        default: () => {
          return [
            {
              wayId: 0,
              travelDate: "", //16 Dec
              flight: {
                flightId: 0,
                flightName: ""
              },
              className: "",
              dept: "", //11:10
              fromSector: {
                sectorId: 0,
                sectorName: ""
              },
              toSector: {
                sectorId: 0,
                sectorName: ""
              },
              pass: 0,
              passType: "ADULT", //CHILD, INFANT
              fare: 0,
              baseFare: 0,
              commissionPercent: 0,
              commission: 0,
              usdTotalCommission: 0,
              mmkTotalCommission: 0,
              usdAmount: 0,
              mmkAmount: 0,
              status: String
            }
          ];
        }
      },
      forPerson: Array
    }
  },
  methods: {
    saveWay: function(way) {
      let roundTripId = this.$moment().millisecond();
      if (this.roundTrip) {
        this.roundTripData.roundTripId = roundTripId;
      }
      way.forEach(v => {
        let found = true;
        do {
          let index = this.voucher.way.findIndex(value => {
            return value.wayId == this.localId;
          });
          if (index < 0) {
            found = false;
          } else {
            found = true;
            this.localId--;
          }
        } while (found);

        v.wayId = this.localId;
        this.localId--;
      });
      if (this.roundTrip) {
        way[0].roundTripId = roundTripId;
      }
      this.roundTrip = false;

      this.voucher.way.push(...JSON.parse(JSON.stringify(way)));

      this.wayDialog = false;
    },
    updateWay: function(way) {
      let index = this.voucher.way.findIndex(p => {
        return p.wayId == way.wayId;
      });

      this.$set(this.voucher.way, index, way);
      this.voucher.way[index] = way;
      this.wayDialog = false;
    },
    editWay: function(way) {
      let copyObj = JSON.parse(JSON.stringify(way));
      this.way = [copyObj];
      this.wayDialog = true;
    },

    addWay: function() {
      this.way = [
        {
          wayId: 0,
          travelDate: "", //16 Dec
          flight: {
            flightId: 0,
            flightName: ""
          },
          className: "",
          dept: "", //11:10
          fromSector: {
            sectorId: 0,
            sectorName: ""
          },
          toSector: {
            sectorId: 0,
            sectorName: ""
          },
          pass: 0,
          passType: "ADULT", //CHILD, INFANT
          fare: 0,
          baseFare: 0,
          commissionPercent: 0,
          commission: 0,
          usdTotalCommission: 0,
          mmkTotalCommission: 0,
          usdAmount: 0,
          mmkAmount: 0,
          status: null
        }
      ];
      this.$refs.wayDialog.addPersonCount({ adult: 0, child: 0, infant: 0 });
      this.wayDialog = true;
    },
    deleteWay: function(way) {
      console.log("DeleteWay");
      console.log(way.wayId);

      if (way.roundTripId != null) {
        this.voucher.way = this.voucher.way.filter(data => {
          if (data.roundTripId != null && data.roundTripId == way.roundTripId) {
            if (data.wayId <= 0) {
              return false;
            } else {
              data.status = "INACTIVE";
            }
          }
          return true;
        });
      } else {
        if (way.wayId <= 0) {
          this.voucher.way = this.voucher.way.filter(data => {
            if (data.wayId == way.wayId) {
              return false;
            }
            return true;
          });
        } else {
          way.status = "INACTIVE";
        }
      }
    },
    convert: function() {
      this.convertionDialog = true;
    },
    writeConversion: function() {
      this.voucher.remark =
        this.result +
        "x" +
        this.conversionRate +
        "=" +
        this.conversionAmt +
        "\n" +
        this.voucher.remark;
      this.voucher.usdReceive = this.result;
      this.convertionDialog = false;
    },
    saveVoucher: function() {
      this.loading = true;
      voucherService
        .saveVoucher(this.voucher)
        .then(response => {
          console.log("Save Voucher");
          console.log(response.data);
          this.$store.commit(
            "TempVoucher/removeVoucherByVoucherCode",
            this.voucher.voucherCode
          );
          this.$swal2
            .fire({
              icon: "success",
              title: "Save Voucher success",
              showConfirmButton: false,
              timer: 1500
            })
            .then(v => {
              let query = {
                voucherId: response.data.voucherId,
                code: 0
              };
              this.$router
                .push({
                  path: "/voucherPrint",
                  query
                })
                .catch(() => {});
            });
        })
        .catch(e => {
          console.log(e);
          this.$swal2.fire({
            icon: "error",
            title: e.response.data.message,
            showConfirmButton: false
          });
          this.loading = false;
        });
    },
    updateVoucher: function() {
      console.log("Update Voucher");
      this.loading = true;
      voucherService
        .updateVoucher(this.voucher.voucherId, this.voucher)
        .then(data => {
          this.$emit("update:voucher", data);
          let date = this.$moment().format("DD-MM-YYYY");
          let query = {
            fromDate: date,
            toDate: date
          };
          this.$router
            .push({
              path: "/voucherList",
              query
            })
            .catch(() => {});
        })
        .catch(e => {
          this.$swal2.fire({
            icon: "error",
            title: e.response.data.message,
            showConfirmButton: false
          });
          this.loading = false;
        });
    },
    roundTripWay: function(way) {
      let copyObj = JSON.parse(JSON.stringify(way));
      console.log("RoundTrip");
      console.log(copyObj);
      //this.way = this.way.filters(f => false);
      this.$set(this.way, 0, {
        wayId: 0,
        travelDate: "", //16 Dec
        flight: copyObj.flight,
        className: "",
        dept: "", //11:10
        fromSector: {
          sectorId: copyObj.toSector.sectorId,
          sectorName: copyObj.toSector.sectorName
        },
        toSector: {
          sectorId: copyObj.fromSector.sectorId,
          sectorName: copyObj.fromSector.sectorName
        },
        pass: 0,
        passType: copyObj.passType, //CHILD, INFANT
        fare: 0,
        baseFare: 0,
        commissionPercent: 0,
        commission: 0,
        usdTotalCommission: 0,
        mmkTotalCommission: 0,
        usdAmount: 0,
        mmkAmount: 0
      });

      this.roundTrip = true;
      this.roundTripData = way;
      this.$refs.wayDialog.addPersonCount({ adult: 0, child: 0, infant: 0 });
      this.wayDialog = true;
    },
    addOther: function(item) {
      console.log(item);

      //if(item.travelDate != "" && item.flight.flightId !=0 && item.dept !="" && item.fromSector.sectorId!=0 && item.toSector.sectorId!=0);
      let copyItem = JSON.parse(JSON.stringify(item));
      copyItem.roundTripId = null;
      copyItem.wayId = 0;
      copyItem.fare = 0;
      copyItem.pass = 0;
      copyItem.baseFare = 0;
      copyItem.commissionPercent = 0;
      copyItem.commission = 0;
      copyItem.usdTotalCommission = 0;
      copyItem.mmkTotalCommission = 0;
      copyItem.usdAmount = 0;
      copyItem.mmkAmount = 0;
      copyItem.status = "WEIGHT";
      this.otherWay = copyItem;
      this.otherDialog = true;
    },
    editOther: function(item) {
      console.log(item);
      let copyItem = JSON.parse(JSON.stringify(item));
      copyItem.roundTripId = null;
      copyItem.commissionPercent = 0;
      copyItem.commission = 0;
      copyItem.usdTotalCommission = 0;
      copyItem.mmkTotalCommission = 0;
      this.otherWay = copyItem;
      this.otherDialog = true;
    },
    saveOtherCharges: function(way) {
      let found = true;
      do {
        let index = this.voucher.way.findIndex(value => {
          return value.wayId == this.localId;
        });
        if (index < 0) {
          found = false;
        } else {
          found = true;
          this.localId--;
        }
      } while (found);
      way.wayId = this.localId;
      this.localId--;

      this.voucher.way.push(JSON.parse(JSON.stringify(way)));

      this.otherDialog = false;
    },
    updateOtherCharges: function(way) {
      let index = this.voucher.way.findIndex(p => {
        return p.wayId == way.wayId;
      });
      this.$set(this.voucher.way, index, way);
      this.voucher.way[index] = way;
      this.otherDialog = false;
    }
  },
  computed: {
    result: function() {
      if (this.conversionRate == 0) {
        return 0;
      }
      return (this.conversionAmt / this.conversionRate).toFixed(0);
    }
  },
  components: {
    "way-dialog": WayDialog,
    "weight-or-wheel-dialog": WeightOrWheelDialog
  },
  watch: {
    "voucher.way": {
      handler: function(ways) {
        if (ways == null) {
          return;
        }
        let usdResult = 0;
        let mmkResult = 0;
        let passCount = 0;
        ways.forEach(value => {
          if (value.status != "INACTIVE") {
            usdResult += value.usdAmount - value.usdTotalCommission;
            mmkResult += value.mmkAmount - value.mmkTotalCommission;
            passCount += Number(value.pass);
          }
        });
        this.voucher.totalPass = passCount;
        this.voucher.usdTotal = usdResult - this.voucher.usdDiscount;
        this.voucher.mmkTotal = mmkResult - this.voucher.mmkDiscount;
      },
      deep: true
    },
    "voucher.mmkReceive": function(value) {
      this.voucher.mmkBalance = this.voucher.mmkTotal - value;
    },
    "voucher.usdReceive": function(value) {
      this.voucher.usdBalance = this.voucher.usdTotal - value;
    },
    "voucher.usdTotal": function(value) {
      this.voucher.usdBalance = value - this.voucher.usdReceive;
    },
    "voucher.mmkTotal": function(value) {
      this.voucher.mmkBalance = value - this.voucher.mmkReceive;
    },
    "voucher.mmkDiscount": function(value) {
      let usdResult = 0;
      let mmkResult = 0;

      this.voucher.way.forEach(value => {
        if (value.status != "INACTIVE") {
          usdResult += value.usdAmount - value.usdTotalCommission;
          mmkResult += value.mmkAmount - value.mmkTotalCommission;
        }
      });
      this.voucher.usdTotal = usdResult - this.voucher.usdDiscount;
      this.voucher.mmkTotal = mmkResult - this.voucher.mmkDiscount;
    },
    "voucher.usdDiscount": function(value) {
      let usdResult = 0;
      let mmkResult = 0;
      this.voucher.way.forEach(value => {
        if (value.status != "INACTIVE") {
          usdResult += value.usdAmount - value.usdTotalCommission;
          mmkResult += value.mmkAmount - value.mmkTotalCommission;
        }
      });

      this.voucher.usdTotal = usdResult - this.voucher.usdDiscount;
      this.voucher.mmkTotal = mmkResult - this.voucher.mmkDiscount;
    },
    "voucher.onePersonMMK": function() {
      this.voucher.mmkDiscount =
        this.voucher.totalPass * this.voucher.onePersonMMK;
    },
    "voucher.onePersonUsd": function() {
      this.voucher.usdDiscount =
        this.voucher.totalPass * this.voucher.onePersonUsd;
    },
    "voucher.totalPass": function() {
      console.log(" total cal ");
      if (this.voucher.totalPass != "") {
        if (this.voucher.totalPass > 0 && this.voucher.onePersonUsd > 0) {
          this.voucher.usdDiscount =
            Number(this.voucher.totalPass) * Number(this.voucher.onePersonUsd);
          this.voucher.mmkDiscount =
            Number(this.voucher.totalPass) * Number(this.voucher.onePersonMMK);
        }
        if (this.voucher.totalPass > 0 && this.voucher.onePersonMMK > 0) {
          this.voucher.mmkDiscount =
            Number(this.voucher.totalPass) * Number(this.voucher.onePersonMMK);
        }
      } else {
        this.totalPass = Number(0);
        this.voucher.usdDiscount = Number(0);
        this.voucher.mmkDiscount = Number(0);
      }
    }
  },
  filters: {
    zeroMinusFilter: function(value) {
      if (value == 0) {
        return "";
      } else {
        return -Math.abs(value);
      }
    },
    zeroFilter: function(value) {
      if (value == 0) {
        return "";
      }
      return value;
    }
  }
};
</script>

<style scoped>
.tbl-way table,
.tbl-way td,
.tbl-way th {
  padding: 4px;
}

.tbl-way thead td {
  font-size: 14px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 3px;
  padding-right: 3px;
  border: 1px solid #e0e0e0;
  background: #4caf50 !important;
  text-align: center !important;
  color: white !important;
}

.tbl-way thead th {
  font-size: 14px;
  padding: 2px;
  height: 24px;
  border: 1px solid #e0e0e0;
  background: #4caf50 !important;
  text-align: center !important;
  color: white !important;
}

.tbl-way tbody th,
.tbl-way tbody td {
  font-size: 13px;
  padding-left: 3px;
  border: 1px solid #e7e7e7;
  padding-right: 1px;
}
.row-shadow {
  box-shadow: inset 0px 2px 8px -5px rgba(50, 50, 50, 0.75),
    inset 0px -2px 8px -5px rgba(50, 50, 50, 0.75);
}
</style>
